
@font-face {
    font-family: 'Source Sans Pro';
    font-style: normal;
    font-weight: 500;
    src: local('sans-serif'), local('sans-serif'), url('https://fonts.googleapis.com/css?family=Source+Sans+Pro:100,200,300,400,500,600,700,800,900') format('woff2');
    font-display: swap;
}

@font-face {
    font-family: 'Heebo';
    font-style: normal;
    font-weight: 500;
    src: local('sans-serif'), local('sans-serif'), url('https://fonts.googleapis.com/css?family=Heebo:100,200,300,400,500,600,700,800,900') format('woff2');
    font-display: swap;
}
body {
    background-color: lightgray;
    font-family: 'Source Sans Pro', sans-serif;
    overflow-x: hidden !important;
}

li.social-item {
    list-style-type: none;
    float:left;
    display:inline;
}

header div.cta .social {
    font-size: 1.25em;
}

#top-brand.scrolled .head-col {
    padding-top: 5px !important;
    padding-bottom: 5px !important;
}
#top-brand.scrolled,#top-brand.scrolled .navbar {
    padding:0 !important;
}
.scrolled img.logo  {
    max-height: 2em;
    padding-top:4px !important;
    width:105px !important;
}
header div.brand img {
    max-height:3em
}

.animated-button {
    text-transform: uppercase;
    border-width: 0px;
    border-radius: 100px;
    letter-spacing: 2px;
    font-size: 18px;
    font-weight:600;
}
.animated-button::after {
    content: '\f0a9';
    font-family: "FontAwesome";
    margin-left:-1em;
    opacity: 0;
    transition: all 300ms ease 0ms;
}
.animated-button:hover::after {
    opacity: 1;
    margin-left: 1em;
}

.btn-white:hover {
    background: #b2b2b2;
}

#accessibilityBar {
    -webkit-transition: all .5s ease-in-out;
    -moz-transition: all .5s ease-in-out;
    -o-transition: all .5s ease-in-out;
    transition: all .5s ease-in-out;
    z-index: 999999;
    position: fixed;
    top: 50%;
    right: 0;
    transform: translate(100%,  -50%);
    background-color: rgba(34, 34, 34, .75);
    border-radius: .5rem 0 0 .5rem;
    box-shadow: 0 5px 30px rgba(34, 34, 34, 0);
}
#accessibilityBar * {
    box-sizing: border-box;
}
#accessibilityBar.active {
    transform: translate(0,  -50%);
    box-shadow: 0 5px 30px rgba(34, 34, 34, .5);
}
#accessibilityBar button {
    outline: none !important;
    box-shadow: none !important;
    -webkit-appearance: none;
    cursor: pointer;
}

#accessibilityBar button#universalAccessBtn.collapsed span {
    opacity: 0;
    right: 50%}
#accessibilityBar button#universalAccessBtn:hover span {
    opacity: 1;
    right: 120%}
#accessibilityBar button.setAccessibility {
    font-size: 12px;
    font-size: .75rem;
    -webkit-transition: all .3s ease-in-out;
    -moz-transition: all .3s ease-in-out;
    -o-transition: all .3s ease-in-out;
    transition: all .3s ease-in-out;
    color: #222;
    font-weight: 700;
    background-color: #eee;
    width: 100%;
    padding: .1rem .2rem;
    border-radius: .25rem;
    border: none;
    display: flex;
    align-items: center;
    margin: .25rem;
}
#accessibilityBar button.setAccessibility:hover {
    background-color: #fff;
}
.grecaptcha-badge {
    width: 70px !important;
    overflow: hidden !important;
    transition: all 0.3s ease !important;
    left: 4px !important;
}